import React from 'react';
import CountUp from 'react-countup';
import { Module,
         ModuleHeader,
         ModuleHeaderTitle,
         ModuleMain,
         ModuleMainNumber,
         ModuleMainText,
         ModuleMainAmount,
         ModuleMainAmountText
       } from './styles';


const ModuleComponent = (props) => (
<Module>
  <ModuleHeader
  style={props.style}>
    <ModuleHeaderTitle>
      {props.title}
    </ModuleHeaderTitle>

    </ModuleHeader> 
    <ModuleMain>
     <ModuleMainNumber>
     <CountUp 
     end={props.number} 
     duration={2}
     />
     </ModuleMainNumber>
     <ModuleMainText>
     {props.textNumber}
     </ModuleMainText>
     <ModuleMainAmount>
     {props.amount}
     </ModuleMainAmount>
     {/*<ModuleMainAmountText>
     {props.textAmount}
     </ModuleMainAmountText> */}
    </ModuleMain>  
</Module>

);

export default ModuleComponent;