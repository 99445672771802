import { currentProfile } from "./currenProfile";

/**
 * Guardar email desde localStorage
 */
export const saveEmail = (email) => {
  localStorage.setItem("email", email);
};

/**
 * Guardar token en localStorage
 */
export const saveToken = (token) => {
  localStorage.setItem("token", token);
};

/**
 * Guardar username en localStorage
 */
export const saveUsername = (username) => {
  localStorage.setItem("username", username);
};

/**
 * Obtiene token desde localStorage
 */
export const getEmail = () => {
  return localStorage.getItem("email");
};

/**
 * Obtiene token desde localStorage
 */
export const getToken = () => {
  return localStorage.getItem("token");
};

/**
 * Obtiene apiKey
 */
export const getApiKey = () => {
  return "hnaq4zoZvx3adaZdeSWfP6bdGxgRb5XmavlshcA2";
};

/**
 * Obtiene username desde localStorage
 */
export const getUsername = () => {
  return localStorage.getItem("username");
};

/**
 * Elimina token en localStorage
 */
export const deleteToken = () => {
  localStorage.removeItem("token");
};

/**
 * Elimina username en localStorage
 */
export const deleteUsername = () => {
  localStorage.removeItem("username");
};

/**
 * Limpiar session localStorage
 */
export const clearSession = () => {
  deleteToken();
  deleteUsername();
};

/**
 * Validar session en localStorage
 */
export const validateSession = () => {
  return ((getToken() !== undefined && getToken() !== null) &&
    (getUsername() !== undefined && getUsername() !== null));
};
