import React, { Fragment } from "react";
import * as env from "../../environment";

import "./json.css";
import JSONPretty from "react-json-pretty";
import { TitleRequestResponse, ContainerRequestResponse } from "./styles";
import Buttons from "../UIComponents/button";
import { getToken } from "../utils/session";

const LOADING_IMG = "/images/loading.gif";
const LUP_IMG = "/images/lup.png";
const ERROR_IMG = "/images/errorService.png";
const SUCCESS_IMG = "/images/success.png";
const INFO_IMG = "/images/info.png";

const { API_URL_MOTOR, API_URL_MOTOR_8080, API_URL } = env[process.env.NODE_ENV];

const Template = ({ name, request, response, message_error, message }) => {
  if (name === "") {
    return null;
  }
  if (name === "loader") {
    return (
      <Fragment>
        <h3 style={{ fontSize: 15, fontWeight: "300" }}>
          Procesando Solicitud
        </h3>
        <img
          src={LOADING_IMG}
          alt="Loader.gif"
          width="160px"
          height="160px"
          style={{ marginTop: "27px" }}
        />
      </Fragment>
    );
  }

  if (name === "load-Login") {
    return (
      <Fragment>
        <h3 style={{ fontSize: 15, fontWeight: "300" }}>Iniciando Sesion</h3>
        <img
          src={LOADING_IMG}
          alt="Loader.gif"
          width="160px"
          height="160px"
          style={{ marginTop: "27px" }}
        />
      </Fragment>
    );
  }

  if (name === "notFound") {
    return (
      <Fragment>
        <h3 style={{ fontSize: 15, fontWeight: "300", marginBottom: "10px" }}>
          No se Encontraron Resultados
        </h3>

        <img
          src={LUP_IMG}
          alt="Loader.gif"
          width="160px"
          height="160px"
          style={{ marginTop: "27px", marginBottom: "10px" }}
        />
      </Fragment>
    );
  }

  if (name === "error") {
    console.log();
    return (
      <Fragment>
        <h3 style={{ fontSize: 15, fontWeight: "300", marginBottom: "10px" }}>
          {message_error}
        </h3>

        <img
          src={ERROR_IMG}
          alt="Loader.gif"
          width="130px"
          height="130px"
          style={{ marginTop: "37px", marginBottom: "10px" }}
        />

      </Fragment>
    );
  }

  if (name === "personalized") {
    return (
      <Fragment>
        <h3 style={{ fontSize: 15, fontWeight: "300", marginBottom: "10px" }}>
          { message }
        </h3>

        <img
          src={INFO_IMG}
          alt="Loader.gif"
          width="130px"
          height="130px"
          style={{ marginTop: "37px", marginBottom: "10px" }}
        />
      </Fragment>
    );
  }

  if (name === "errorPass") {
    return (
      <Fragment>
        <h3 style={{ fontSize: 15, fontWeight: "300", marginBottom: "10px" }}>
          El usuario no existe o la password ingresada es incorrecta<br />Por favor intente nuevamente
        </h3>

        <img
          src={ERROR_IMG}
          alt="Loader.gif"
          width="130px"
          height="130px"
          style={{ marginTop: "37px", marginBottom: "10px" }}
        />
      </Fragment>
    );
  }

  if (name === "success") {
    return (
      <Fragment>
        <h3 style={{ fontSize: 15, fontWeight: "300", marginBottom: "10px" }}>
          Se Proceso Con Exito
        </h3>

        <img
          src={SUCCESS_IMG}
          alt="Loader.gif"
          width="130px"
          height="130px"
          style={{ marginTop: "37px", marginBottom: "10px" }}
        />
      </Fragment>
    );
  }

  if (name === "requestModal") {
    return (
      <Fragment>
        <TitleRequestResponse>Request</TitleRequestResponse>
        <hr></hr>
        <br></br>
        <ContainerRequestResponse>
          <p>
            <JSONPretty id="json-pretty" data={request}></JSONPretty>
          </p>
        </ContainerRequestResponse>

        <TitleRequestResponse>Response</TitleRequestResponse>
        <hr></hr>
        <br></br>
        <ContainerRequestResponse>
          <p>
            <JSONPretty id="json-pretty" data={response}></JSONPretty>
          </p>
        </ContainerRequestResponse>
      </Fragment>
    );
  }

  if (name === "comment") {
    return (
      <Fragment>
        <TitleRequestResponse>Comentario</TitleRequestResponse>
        <hr></hr>
        <br></br>
        <textarea rows={20} disabled={true} style={{ maxWidth: '500px', width: '500px' }}>{message}</textarea>
      </Fragment>
    );
  }


  if (name === "requestModalNotify") {
    console.log(request);
    return (
      <Fragment>
        <TitleRequestResponse>Request</TitleRequestResponse>
        <hr></hr>
        <br></br>
        <div>
          <textarea id="requestNotify" cols="60" rows="12">{request.request}</textarea>
        </div>
        <br></br>
        <Buttons.Primary onClick={(e) => {
          e.preventDefault();
          let xx = document.getElementById('requestNotify');
          let x = xx.value;

          let endpoint = `${API_URL}/api/integration/update`;

          let requestt = "{";
          console.log(request);
          if (request.integrationId !== undefined && request.integrationId !== "") {
            requestt = requestt + '"integrationId": ' + request.integrationId;
          }

          if (x !== undefined && x !== "" && x !== "-1") {
            requestt = requestt + ((requestt === "{") ? "" : ", ") + '"request": "' + x + '"';
          }

          requestt = requestt + "}";

          console.log(requestt);

          var json = JSON.stringify(requestt);
          console.log("request : " + JSON.parse(json));

          const requestOptions = {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: getToken()
            },
            body: JSON.parse(json)
          };

          fetch(endpoint, requestOptions)
            .then(result => result.json())
            .then(response => {
              console.log(response);
              reInject(request.data.data, xx);
            });

        }}>
          Empujar
        </Buttons.Primary>
      </Fragment>
    );
  }
};

function reInject(data, x) {
  let apiUrlEngineContext = `${API_URL_MOTOR_8080}/api/instance/reinject`;

  let requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify(data)
  };

  console.log(data);

  fetch(apiUrlEngineContext, requestOptions)
    .then((result) => {
      console.log(result);
      if (result.ok) {
        result
          .json()
          .then((response) => {
            console.log(response);
            x.value = 'Operación Exitosa!!';
            x.contentEditable = false;
          })
          .catch((error) => {
            console.log("Hubo un problema con la petición Fetch:" + error.message);
          });
      }
    })
    .catch((error) => {
      console.log("Hubo un problema con la petición Fetch:" + error.message);
    });

}

export default Template;
