import styled from 'styled-components';

export const FormBlock = styled.div`
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
`;

export const BlockFormMain = styled.div`
  display: flex;
  flex-direction: row; 

  @media (max-width: 767px) {
    flex-direction: column; 
  }
`;

export const FormTitle = styled.h1`
  border-bottom: 1px solid #CCCCCC;
  box-sizing: border-box;
  color: #333333;
  font-size: 24px;
  font-weight: bold;
  margin: 0 20px 30px 20px;
  padding: 0 0 10px 0;
`;

export const FormRow = styled.div`
  box-sizing: border-box;
  padding: 10px 0;
  width: 100%;
`;

export const HalfBlock = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 50%;

  @media (max-width: 767px) {
    width: 100%;
  }
`;
export const CenterBlock = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 20% 0 20%;
  width: 100%;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const FormName = styled.p`
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  padding: 0 0 10px 0;
`;

export const FormFieldName = styled.p`
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;

`;

export const ActionForm = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 20px;
  text-align: right;
  width: 100%;  
`;

export const ActionFormCenter = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 0px;
  text-align: right;
  width: 100%;  
`;