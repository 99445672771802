export const production = {

  API_URL: 'http://lb-reca-saesa-632389069.us-west-2.elb.amazonaws.com:8080', // Api reca nueva version prod
  
  API_URL_INYECTOR: 'http://ec2-54-212-192-251.us-west-2.compute.amazonaws.com:7024', // Api inyector nuevo
  
  API_URL_MOTOR: 'http://ecs-engine-lb-178075019.us-west-2.elb.amazonaws.com:8090',
  
  API_URL_MOTOR_8080: 'http://ecs-engine-lb-178075019.us-west-2.elb.amazonaws.com:8080',
  
  API_URL_AUDITORIA: 'http://ec2-18-237-247-255.us-west-2.compute.amazonaws.com:7029', // Api auditoria
  
  API_KEY_AUDITORIA: 'Audit123',
  
  URL_BASE: 'https://eto8b1ky33.execute-api.us-west-2.amazonaws.com/prod/api/rpa/core/v1/',

  API_KEY: 'gi15kVrLNj1GswhD7re3Y7F5uQpkWc5x1AmO7Rtj',

  API_FORMS: 'https://eto8b1ky33.execute-api.us-west-2.amazonaws.com/prod/',
};

export const development = {
  
  API_URL: 'http://lb-reca-saesa-632389069.us-west-2.elb.amazonaws.com:8080', // Api reca nueva version prod
  
  API_URL_INYECTOR: 'http://ec2-54-212-192-251.us-west-2.compute.amazonaws.com:7024', // Api inyector nuevo
  
  API_URL_MOTOR: 'http://ecs-engine-lb-178075019.us-west-2.elb.amazonaws.com:8090',
  
  API_URL_MOTOR_8080: 'http://ecs-engine-lb-178075019.us-west-2.elb.amazonaws.com:8080',
  
  API_URL_AUDITORIA: 'http://ec2-18-237-247-255.us-west-2.compute.amazonaws.com:7029', // Api auditoria
  
  API_KEY_AUDITORIA: 'Audit123',
  
  URL_BASE: 'https://yfloqq1tf4.execute-api.us-west-2.amazonaws.com/dev/api/rpa/core/v1/',

  API_KEY: 'hnaq4zoZvx3adaZdeSWfP6bdGxgRb5XmavlshcA2',

  API_FORMS: 'https://yfloqq1tf4.execute-api.us-west-2.amazonaws.com/dev/',
};

export const test = {
  
  API_URL: 'http://lb-reca-saesa-632389069.us-west-2.elb.amazonaws.com:8080', // Api reca nueva version prod
  
  API_URL_INYECTOR: 'http://ec2-54-212-192-251.us-west-2.compute.amazonaws.com:7024', // Api inyector nuevo
  
  API_URL_MOTOR: 'http://ecs-engine-lb-178075019.us-west-2.elb.amazonaws.com:8090',
  
  API_URL_MOTOR_8080: 'http://ecs-engine-lb-178075019.us-west-2.elb.amazonaws.com:8080',
  
  API_URL_AUDITORIA: 'http://ec2-18-237-247-255.us-west-2.compute.amazonaws.com:7029', // Api auditoria
  
  API_KEY_AUDITORIA: 'Audit123',
  
  URL_BASE: 'https://sfd5xr0yla.execute-api.us-west-2.amazonaws.com/qa/api/rpa/core/v1/',

  API_KEY: 'CE2TM1ZiGAacbbu523igpaWz0aLfFYIqac4IRRq2',

  API_FORMS: 'https://sfd5xr0yla.execute-api.us-west-2.amazonaws.com/qa/',
  

};

export default development;
