import React, {Component} from 'react';
import { Route, BrowserRouter as Router, Switch,HashRouter,Redirect } from 'react-router-dom';
import { Container, MainHome, BlockContent}  from './styles';
import NavegationComponent from '../navegationMenu';
import HeaderModuleComponent from '../UIComponents/headerModule';


class Home extends Component {
  
  render() {
    return (
<HashRouter>
  <Container>
    <NavegationComponent />
      <MainHome>
      <HeaderModuleComponent />
      <BlockContent>
          <Switch>
              {
                this.props.routes.map(route => (
                <Route
                key={route.path}
                path={route.path}
                component={route.component}
                exact={route.exact}
              />
              ))}
              <Redirect to="/home/Dashboard" />
          </Switch>           
      </BlockContent>
    </MainHome>
  </Container>
</HashRouter>

    );
  }
}

export default Home; 