import styled from 'styled-components';

export const LoginContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  justify-content: center;
  margin: 0;
  padding: 20px;
  width: 100%;
`;

export const LoginMain = styled.div`
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 6px 13px -3px rgba(0,0,0,0.38);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: auto;
  width: 750px;
`;

export const LoginInfo = styled.div`
  background: #DD0021;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 30px;
  width: 50%;
`;

export const LoginTitleInfo = styled.h2`
  font-size: 28px;
  font-weight: bold;
  color: #FFFFFF;
  margin: 0;
`;

export const LoginTextInfo = styled.p`
  font-size: 18px;
  color: #FFFFFF;
`;

export const LoginForm = styled.div`
  background: #FFFFFF;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  width: 50%;
`;

export const LoginFormTitle = styled.h2`
  color: #333333;
  font-size: 24px;
  padding: 0 0 10px 0;
`;

export const LoginFormText = styled.p`
  font-size: 16px;
  padding: 15px 0;
`;

export const LogoAligare = styled.img`
    
    width: 80px;
    margin-left: -120%;
    border: 5px solid white;
    border-radius: 6px;
    background-color: white;
`;

export const LogoSalfa = styled.img`
    
    width: 100px;
    margin-left: 100%;
    margin-left: 87%;
    padding: 10px;
`;

export const LoginInfoContent = styled.div`
 display: flex;
 flex-direction: column;
 height: 100%;
 justify-content: center;
 width: 100%;
`;

export const ButtonBlock = styled.div `
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 0;
  width: 100%;
`;

export const StyleInput = styled.input`
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  height: 50px;
  outline: none;
  padding: 10px;
  width: 100%;

  &:focus {
    box-shadow: -2px 9px 6px -5px rgba(0,0,0,0.27);
    transition: box-shadow 0.3s ease-in-out;
  }

`;

export const StyledParrafo = styled.p`
  display: block;
  color:red; 
  font-family: 'Lato', sans-serif;
  font-size: 0.75rem;
  margin: 0;
`;