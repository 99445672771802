import styled from 'styled-components';

export const Dashboard = styled.div `
 display: flex;
 flex-direction: column;
 width: 100%;
`;

export const PodContainer = styled.div`
 box-sizing: border-box;
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
 padding: 20px 0;
 width: 100%;
`;

export const TitleMain = styled.h2`
  border-bottom: 1px solid #CCCCCC;
  display: flex;
  font-size: 35px;
  margin: 0 0 20px 0;
  padding: 0 0 5px 0;
  width: 100%;
`;

export const SubTitle = styled.div`
  align-items: center;
  background: #FFFFFF;
  border-left: 8px solid #4a4a4a;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  font-size: 18px;
  height: 50px;  
  padding: 0 10px;
  width: 100%;
`;

export const StyleInput = styled.input`
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  height: 50px;
  outline: none;
  padding: 10px;
  width: 100%;

  &:focus {
    box-shadow: -2px 9px 6px -5px rgba(0,0,0,0.27);
    transition: box-shadow 0.3s ease-in-out;
  }

  // &:disabled {
  //   background: grey
  // }
`;


export const StyleSelect = styled.select`  
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  height: 50px;
  // outline: none;
  padding: 10px;
  width: 100%;
`;