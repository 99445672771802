import styled from 'styled-components';

const Button = styled.button`
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  font-family: 'Lato', sans-serif;  font-size: 15px;
  font-weight: bold;
  font-size: 14px;
  height: 50px;
  padding: 0 30px;
  outline: none;
  width: 100%;
  max-width: 200px;

  &:hover {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s ease-in;
  }

  @media (max-width: 767px) {
    max-width: 100%; 
  }
`;

const Primary = styled(Button)`
   background: #ff4063;
   color: #FFFFFF;

   &:hover {
    color: #FFFFFF;
  }
`;

const Secondary = styled(Button)`
   background: #FFFFFF;
   color: #333333;
   border: 1px solid #CCCCCC;

   &:hover {
    color: #333333;
  }
`;

const BtnAction = styled.span`
  background: #f6f6f6;
  color: #292929;
  margin: 0px 2px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  padding: 10px 10px;
  cursor: pointer;
  &:hover {
    color: #070707;
    background-color: #f1f1f1;
  }
`;

const btnDisable = styled(Button)`
  background: #F2AAB7;
  color: #FFFFFF;

  &:hover {
  color: #FFFFFF;
  }
`;

const Clear = styled(Button)`
   color: #FFFFFF;
   width: 110px;
   margin-left: 10px;
   background: #b4a7a9;
`;


const Tertiary = styled(Button)`
  color: #FFFFFF;
  margin-left: 10px;
  background: #b4a7a9;
`;


const Buttons = {
  Button,
  Primary,
  Secondary,
  btnDisable,
  Clear,
  Tertiary,
  BtnAction
}


export default Buttons;