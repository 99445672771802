import React from 'react';
import {
        HeaderModule, 
        ContentAligare,
        LogoAligare,
        ProductName
      } from './styles';

const HeaderModuleComponent = () => (
 <HeaderModule>

  <ContentAligare>
    <LogoAligare src="./images/logo-aligare.png" />
    <ProductName>
        Sistema de RPA
    </ProductName>
  </ContentAligare>
 </HeaderModule>
);

export default HeaderModuleComponent;