import React from 'react';
import ModuleComponent from '../module';
import * as env from '../../../environment';
import { number_format, get_box_color } from '../../utils/utils';
import { getToken } from "../../utils/session";

const summarytype = 'status';
const { API_URL } = env[process.env.NODE_ENV];
// const { API_USER } = env[process.env.NODE_ENV];
// const { API_PASS } = env[process.env.NODE_ENV]; 

export class stateProcess extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      summaryBoxes: [],
      authToken: getToken(),
      dateSelectedIni: null,
      dateSelectedFin: null,
      store: 0,
    };
  }

  componentDidMount() {
    this.setState({ summaryBoxes: this.props.summaryBoxes });
  }

  render() {
    if (this.state.summaryBoxes.length > 0) {
      return (
        <React.Fragment>
          {this.state.summaryBoxes.map(box => {
            return (
              <ModuleComponent
                title={box.header.title}
                number={box.body.value}
                textNumber=''
                amount={box.footer ? `${box.footer.value} ${box.footer.text}` : ''}
                textAmount=''
                style={get_box_color(box.header.title)}
              />
            )
          })}
        </React.Fragment>
      )
    } else {
      return <p className="text-center">Cargando...</p>
    }
  }
}

export default stateProcess;