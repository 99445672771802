import React from 'react';
import styled from 'styled-components'

const ErrorInput = ({isShow,value}) => (
      isShow 
      ? 
      <Error> {value}</Error> 
      : null
    )

export default ErrorInput;

const Error = styled.samp`
color: #ff3e3e;
font-size: 12px;
opacity: 0.9;
position: unset;
margin-top: -8px;
margin-left: 3px;
text-transform: uppercase;
`