import React, {Fragment} from "react";
import PropTypes from "prop-types";
import * as Sty from "./styles";

const Modal = ({ show, onClose, children, isDetail }) => {
  if (!show) {
    return null;
  }

  Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool,
    children: PropTypes.node,
  };

  return (
    <div onClick={onClose} className="over" style={Sty.background}>
      <div 
         style={ isDetail ? Sty.backgroundModalForDetail :  Sty.backgroundModal } 
      >
        <div style={Sty.modalContent}>
           <div>{children}</div>
        </div>
      </div>
    </div>
    
  );
};

export default Modal;
