import React from "react";
import Buttons from "../UIComponents/button";
import { Link } from "react-router-dom";
import {
  NavegationMenuContainer,
  NavegationMenu,
  UserContainer,
  UserName,
  UserMail,
  ContainerDataUser,
  IconUser,
  NavegationList,
  NavegationListItem,
  NavegationListSubMenu,
  NavegationListSubMenuItem,
  BlockButtonSignOff,
} from "./styles";
import { validateSession, getUsername, clearSession, getEmail } from "../utils/session";
import { currentProfile } from "../utils/currenProfile";

import Amplify, { Auth } from 'aws-amplify';

class NavegationComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      username: "",
      pass: "",

      // logout Amplify Cognito
      region: "",
      userPoolId: "",
      userPoolWebClientId: "",

      hiddenMenu: true,

      //Perfilamiento
      funcProfile: [],
      pruebaFunc: [],
    };
  }

  enableFunctionality() {
    let x = [];
    let y = [];
    currentProfile.getProfileFunctions().then((resolve) => {
      console.log(resolve);
      resolve.map(res => {
        y.push(res.name);
        x.push(res.id)
      });
      this.setState({ funcProfile: x, pruebaFunc: y }, () => console.log(this.state));
    });
  }

  componentDidMount() {
    this.enableFunctionality();
  }

  authorizedMethod = (route) => {
    if (this.state.pruebaFunc.includes(route.pathname)) {
      return true
    }
    return false;
  }
  authorizedMethodTest = (route) => {
    if (this.state.pruebaFunc.includes(route)) {
      return true
    }
    return false;
  }

  render() {
    return (
      <NavegationMenuContainer>
        <NavegationMenu>
          <UserContainer>
            <IconUser src="images/login.svg" />
            <ContainerDataUser>
              <UserName>
                {validateSession()
                  ? getUsername().toUpperCase()
                  : (window.location = "/")}
              </UserName>
              <UserMail>{getEmail()}</UserMail>
            </ContainerDataUser>
          </UserContainer>

          <NavegationList>
            <Link to="/home">
              <NavegationListItem>Dashboard</NavegationListItem>
            </Link>

            <Link to="/home/ConsultaProcesos">
              <NavegationListItem>Consulta Procesos</NavegationListItem>
            </Link>

            {/* <Link to="/home/ConsultaProcesosMatias">
              <NavegationListItem>Consulta Procesos mati</NavegationListItem>
            </Link> */}
            
          </NavegationList>
        </NavegationMenu>
        <BlockButtonSignOff>
          <Buttons.Secondary onClick={this.logout}>
            Cerrar Sesión
          </Buttons.Secondary>
        </BlockButtonSignOff>
      </NavegationMenuContainer>
    );
  }

  async logout() {

    // const currentUser = Auth.currentAuthenticatedUser();
    const currentUser = Auth.userPool.getCurrentUser();
    await currentUser.signOut();
    const holdingName = localStorage.getItem('holdingName');
    
    clearSession();   
    localStorage.clear(); 
    window.location = `/${ holdingName }`
  }

}

export default NavegationComponent;
