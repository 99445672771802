import React from 'react';
import * as env from "../../environment";

import { getToken } from "../utils/session";
import StateProcess from '../UIComponents/stateProcess';
import { Dashboard, PodContainer, TitleMain, SubTitle } from './styles';
import AreaChart from "../areaChart";
import './styles.css';
import Modal from "../modal";
import Template from "../modal/tempalte";
import RefreshIcon from '@material-ui/icons/Refresh';

const { API_KEY, URL_BASE } = env[process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV];

class DashboardComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      apiKey: API_KEY,
      token: getToken(),
      dateSelectedIni: null,
      dateSelectedFin: null,
      isOpen: false,
      modalChildren: "",
      isClosable: false,

      companyInstace: null,

      data: undefined,
      graphData: null,

      titleBoxes: '',
      titleGraph: '',
      graphType: '',
      xtitle: '',
      ytitle: '',

      intervalGraphId: 0
    };


    this.handleChange = this.handleChange.bind(this);
  }

  getSummaryBoxes(IdInstance) {
    const endpointtt = `${URL_BASE}instance/summary/` + IdInstance;
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": this.state.apiKey
      }
    };
    this.setState({ isOpen: true, modalChilren: 'loader', isClosable: false });
    fetch(endpointtt, requestOptions)
      .then(res => res.json())
      .then(data => {

        this.state.titleBoxes = data.data.sections[0].title;

        if (data.status === 200) {
          this.setState({ data: data.data, isOpen: false }, () => {
            this.forceUpdate();
          });
        } else {
          this.setState({ modalChildren: 'notFound', isClosable: true });
        }
      })
      .catch(err => {
        console.log('error getData: ', err);
        this.setState({ modalChilren: 'error', isClosable: true });
      });
  }

  //Obtiene la data del grafico por instancia de la agencia
  getGraphData(instanceID) {
    const endpoint = `${URL_BASE}instance/graph/${instanceID}`;

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": this.state.apiKey
      }
    };

    this.setState({ isOpen: true, isClosable: false, modalChilren: 'loader' });

    fetch(endpoint, requestOptions)
      .then(res => res.json())
      .then(data => {
        if (data.status === 200) {

          // Obtener titulo sobre cajas
          this.state.titleGraph = data.data.label;
          this.state.xlabel = data.data.xtitle;
          this.state.ylabel = data.data.ytitle;
          this.state.graphType = data.data.type;

          let valX;
          data.data.datums.forEach((val, idx) => {

            if (data.data.type === 'time') {
              valX = new Date(val.x);
            } else {
              valX = val.x;
            }

            data.data.datums[idx].x = valX;
          });

          this.setState({ graphData: [data.data], isOpen: false }, () => {
            this.forceUpdate();
          });
        } else {
          this.setState({ modalChilren: 'notFound', isClosable: true });
        }
      })
      .catch(err => {
        console.log('error getGraphData: ', err);
        this.setState({ modalChilren: 'error', isClosable: true });
      })
  }

  componentDidMount() {
    this.getGraphData(localStorage.getItem('instanceId'));
    this.getSummaryBoxes(localStorage.getItem('instanceId'));

    let intervalId = setInterval(() => {
      this.setState({data: undefined, graphData: null})
      this.getGraphData(localStorage.getItem('instanceId'));
      this.getSummaryBoxes(localStorage.getItem('instanceId'));
    }, 60000)//1min

    this.setState({intervalGraphId: intervalId});
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalGraphId);
  }

  refresh = () => {
    this.setState({data: undefined, graphData: null})
    this.getGraphData(localStorage.getItem('instanceId'));
    this.getSummaryBoxes(localStorage.getItem('instanceId'));
  }

  render() {
    return (
      <Dashboard>
        <TitleMain>
          Bienvenido
          <div style={{ width: '100%', float: 'right' }}>
            <button style={{ float: 'right', border: '0px' }} onClick={this.refresh}>
              <RefreshIcon />
            </button>
          </div>
        </TitleMain>
        <SubTitle>
          {this.state.titleBoxes}
        </SubTitle>
        {this.state.data !== undefined ?
          <PodContainer>
            <StateProcess summaryBoxes={this.state.data.sections[0].summaryBoxes}
            />
          </PodContainer>
          : null}
        <div style={{ marginTop: '25px' }}>
          <b style={{ fontSize: '20px' }}>{this.state.titleGraph}</b>
          <hr style={{ width: '100%' }} />
        </div>
        {
          this.state.graphData !== null ?
            <AreaChart graphData={this.state.graphData} graphType={this.state.graphType} />
            : null
        }
        <Modal show={this.state.isOpen}
          onClose={() => {
            this.state.isClosable
              ? this.setState({
                isOpen: !this.state.isOpen
              })
              : console.warn("worker on something...");
          }}>
          <Template name={this.state.modalChilren} />
        </Modal>
      </Dashboard>
    );
  }

  handleChange = (name, event) => {
    this.setState({
      [name]: event
    });
  };
}

export default DashboardComponent;
