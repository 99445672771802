import React, { useEffect } from "react";

import { Chart } from "react-charts";
import ResizableBox from "./ResizableBox";
import "../areaChart/style.css";


export default function AreaChart(props) {
    const series = React.useMemo(
        () => ({
            type: "area"
        }),
        []
    );

    const axes = React.useMemo(
        () => [
            { primary: true, position: "bottom", type: props.graphType },
            { position: "left", type: "linear", stacked: true }
        ],
        []
    );

    const getSeriesStyle = React.useCallback((series) => {
        // Based off my chart bars
        const colorPalette = {
            series1: "#27e2ea",
        };

        return {
            fill: colorPalette[series.label],
            opacity: 0.4,
            line: {
                strokeWidth: '2px',
                stroke: '#25addc'
            }
        };
    }, []);

    useEffect(() => {
        console.log('props areaChart: ', props.graphData);
    }, [])

    return (
        <ResizableBox>
            <Chart data={props.graphData} series={series} axes={axes} getSeriesStyle={getSeriesStyle} tooltip />
        </ResizableBox>
    )

}