import jwt_decode from "jwt-decode";
import * as env from "../../environment";
import { getToken, validateSession } from "./session";

const { API_URL } = env[process.env.NODE_ENV];

export class currentProfile {
    functionalities = [];
    _token = '';

    constructor() {
        console.log('calling constructor');
    }

    static getInstance() {
        if (!currentProfile.instance) {
            return new Promise((resolve, reject) => {
                console.log('nuevo');
                this.getProfileFunctions().then(resp => {
                    resolve(resp);
                }).catch(()=>reject('Algo salio mal'));
            });
        }
        console.log('ya tenia');
        return new Promise((resolve) => resolve(currentProfile.instance));
    }

    static isAuthenticated() {
        return validateSession();
    }

    static getFunctionalities() {
        console.log(this.functionalities);
        return this.functionalities;
    }

    static getProfileFunctions() {
        return new Promise((resolve, reject) => {
            currentProfile.instance = new currentProfile();
            currentProfile.instance._token = getToken();
            console.log(currentProfile.instance);
            if (currentProfile.instance && currentProfile.instance._token) {
                let idUser = jwt_decode(currentProfile.instance._token).idUser;
                let endpoint = `${API_URL}/api/user/get/${idUser}`
                console.log('endpoint: ' + endpoint);

                const requestOptions = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: getToken()
                    }
                };
                fetch(endpoint, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        this.functionalities = [];
                        result.profile.functionalitys.map(func => {
                            this.functionalities.push(func);
                        });
                        resolve(this.functionalities)
                    })
                    .catch(err => {
                        reject(err);
                    });
            }

        });
    }
}
