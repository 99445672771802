import { Redirect } from 'react-router-dom'
import Login from '../components/login';
import Dashboard from '../components/dashboard';
import Home from '../components/home';
import ConsultaProcesosComponentt from '../components/consultaProcesos/indexx';
import SelectAgencyComponent from '../components/selectCompany';
import SelectInstanceComponent from '../components/selectInstance';

const defaultLoginRedirect = '/noHolding'

const routes = [
  {
    exact: true,
    path: '/',
    component: Redirect,
    props: {
      to: defaultLoginRedirect
    }
  },
  {
    exact: true,
    path: '/selectCompany',
    strict: true,
    component: SelectAgencyComponent,
  },
  {
    exact: true,
    path: '/selectInstance',
    strict: true,
    component: SelectInstanceComponent,
  },
  {
    exact: true,
    path: '/home',
    component: Home,
    routes: [
      {
        exact: true,
        path: '/home/Dashboard',
        strict: true,
        component: Dashboard,
      },
      {
        exact: true,
        path: '/home/ConsultaProcesos',
        strict: true,
        component: ConsultaProcesosComponentt,
      }]
  },
  {
    exact: true,
    path: '/:holdingId',
    component: Login
  }
];

export default routes;
