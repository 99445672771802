import styled from 'styled-components';


export const Module = styled.div`
  background: #FFFFFF;
  border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 200px;
  margin: 10px;
  width: 235px;

  &:hover {
   box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
   transition: all 0.2s ease-in;
  }

  @media (max-width: 1280px) {
    margin: 10px 10px 10px 10px;
    width: 230px;
  }

  @media (max-width: 1024px) {
    margin: 10px 10px;
    width: 228px;
  }

  @media (max-width: 768px) {
    margin: 20px 30px 20px 30px;
    width: 40%;
  }

  @media (max-width: 720px) {
    margin: 20px;
    width: 44%;
  }

  @media (max-width: 719px) {
    margin: 10px;
    width: 100%;
  }
`;

export const ModuleHeader = styled.div`
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  height: 50px;
  padding: 5px 10px;
  width: 100%;
`;

  export const ModuleHeaderTitle = styled.p `
  font-size: 14px;
  font-weight: bold;
  font-weight: bold;
  width: 100%;
`;

export const ModuleHeaderIcon = styled.img`
 height: 22px;
 display: flex;
 margin: 0 0 0 auto;
 width: 22px;
 
`;

export const ModuleMain = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 10px 20px;
  width: 100%;
`;

export const ModuleMainNumber = styled.p`
  color: #333333;
  display: flex;
  font-size: 60px;
  justify-content: center;
  width: 100%;
`;

export const ModuleMainText = styled.p`
 border-bottom: 1px solid #CCCCCC;
 box-sizing: border-box;
 color: #333333;
 display: flex;
 font-size: 14px;
 justify-content: center;
 padding: 0 0 15px 0;
 text-align: center;
 width: 100%;
`;

export const ModuleMainAmount = styled.p`
 color: #c50c0c; 
 display: flex;
 font-size: 26px;
 justify-content: center;
 padding: 15px 0 0;
 width: 100%;
`;

export const ModuleMainAmountText = styled.p`
  color: #333333;
  display: flex;
  font-size: 14px;
  justify-content: center;
  width: 100%;
`;

export const ModuleMainLink = styled.p`
  color: #4a90e2;
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin: auto 0 0;
  width: 100%;


  &:hover {
   cursor: pointer;
   text-decoration: underline;  
    }
`;

  
