

export const bgGreenHeader = {
    background: `#4d9360 url(${"./images/ok.svg"})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '25px',
    backgroundPosition: '95% center'
  }
  
  export const bgRedHeader = {
    background: `#ff4063 url(${"./images/error.svg"})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '25px',
    backgroundPosition: '95% center'
  }
  
  export const bgBlueheader = {
    background: `#3c96d3 url(${"./images/process.svg"})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '25px',
    backgroundPosition: '95% center'
  }
  
  export const bgGreyheader = {
    background: '#4a4a4a',
    color: '#FFFFFF',
    width: '100%'
  }