import React, { Fragment } from "react";
import Form from "@rjsf/material-ui";
import "../pagination/styles.css";
import { ActionForm, FormBlock, FormRow, FormTitle, FormFieldName } from "../../generalStyles/mixin";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Modal from "../modal/index";
import Template from "../modal/tempalte"
import { StyleInput } from './styles';
import Buttons from "../UIComponents/button";
import * as env from "../../environment";
import './styles.css';
import moment from 'moment';
import Pagination from "../pagination/pagination";
import DownloadData from "../excel-pdf/download-data";
import Select from 'react-select';

const { format, clean } = require("rut.js");
const { API_KEY, API_FORMS } = env[process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV];

class ConsultaProcesosComponent extends React.Component {

        constructor(props) {
            super(props);
            this.state = {
                servicedata: {}, // respuesta de servicio para obtener filtros
                schema: {}, // react forms, definimos esquema de filtros
                uiSchema: undefined, // react forms, definimos esquema con el formato de filtros
                formData: undefined, // filtros de busqueda enviados por el submit
                // clearFormKey: true, // para limpiar el formulario
                columnsTable: undefined, // se definen las columnas de la tabla
                dataTable: undefined, // registros para dibujar en la tabla
                requestSearch: undefined, // guardamos request del search para la exportacion a pdf/ecel

                //Modal
                isOpen: false,
                modalChildren: '',
                isClosable: false,
                modalMessage: '',

                //Paginador
                pagesSize: undefined,
                currentStatePage: 0,
                totalPages: -1,

                //DataPicker
                maxDays: 90  //Cantidad de dias permitidos entre fecha desde/hasta
            }

            // this.formRef = React.createRef();
        }

        clearForm() {

          window.location.reload();

            // this.setState({
            //     columnsTable: undefined,
            //     dataTable: undefined,
            //     formData: undefined,
            //     requestSearch: undefined,

            //     //Modal
            //     isOpen: false,
            //     modalChildren: '',
            //     isClosable: false,
            //     modalMessage: '',

            //     //Paginador
            //     pagesSize: undefined,
            //     currentStatePage: 0,
            //     totalPages: -1,
            // });
        }

        componentDidMount() {
            console.clear();
            
            this.setState({ isOpen: true, modalChildren: 'loader', isClosable: false });
            this.getForm();
        }

        /**
         * Mapeamos el json formulario al formato del schema
         */
        parseSchema(dataForm) {

        try {

            let filtersForm = "";
            let mandatoryFields = "";
            let rutFieldArr = [];
            let dateFieldArr = [];
            let numberFieldArr = [];
            let stringFieldArr = [];
            let comboBoxFieldArr = [];

            // Obtener filtros
        dataForm.filters.forEach((element) => {

        let filter = `"${element.label}":{
            "title": "${element.label}",
            ${element.elementType === 'textBox' && element.format=== 'string' ? '"type": "string"' : ""}
            ${element.elementType === 'textBox' && element.format=== 'number' ? '"type": "number"' : ""}
            ${element.elementType === 'textBoxRut' ? '"type": "string"' : ""}
            ${element.elementType === 'datePicker' ? '"type": "string"' : ""}
            ${element.elementType === 'comboBox' ? '"type": "string"' : ""}
            
            ${element.elementType === 'datePicker' ? ',"format": "date"' : ""}
            ${element.elementType === 'comboBox' ? `,"enum": ${JSON.stringify(element.values)}` : ""}
        }`;

        filtersForm = filtersForm !== '' ? filtersForm + ',' + filter : filter;

        // Obtener campos obligatorios
        if (element.mandatory) {
          let labelStr = JSON.stringify(element.label);
          mandatoryFields = mandatoryFields === "" ? labelStr : mandatoryFields + ',' + labelStr;
        }

        // Obtener campos tipo rut
        if (element.elementType === 'textBoxRut') {
          rutFieldArr.push([element]);
        }

        // Obtener campos tipo datePicker
        if (element.elementType === 'datePicker') {
          dateFieldArr.push([element]);
        }

        // Obtener campos tipo number
        if (element.elementType === 'textBox' && element.format === 'number') {
          numberFieldArr.push([element.label]);
        }

        // Obtener campos tipo texto
        if (element.elementType === 'textBox' && element.format === 'string') {
          stringFieldArr.push([element.label]);
        }

        // Obtener campos tipo comboBox
        if (element.elementType === 'comboBox') {
          comboBoxFieldArr.push([element]);
        }

      });

      const jsonSchema = `{
        "title": "${dataForm.title}",
        "type": "object",
        "required": [${mandatoryFields}],
        "properties": {${filtersForm}}
      }`;

      this.getUiScheme(rutFieldArr, dateFieldArr, numberFieldArr, stringFieldArr, comboBoxFieldArr);

      this.setState({ schema: JSON.parse(jsonSchema) });

    } catch (err) {
      this.setState({ modalChildren: 'error', isClosable: true });
      console.log('Error Mapeo formulario a schema: ', err);
    };

  }

  /**
   * Generamos uiSchema para los campos con formato
   * @param {*} rutFieldArr 
   */
  getUiScheme(rutFieldArr, dateFieldArr, numberFieldArr, stringFieldArr, comboBoxFieldArr) {
    let uiSchemaArr = {};


    // RUT FILTERS
    let inputRut;

    rutFieldArr.forEach((item, i) => {
      
      inputRut = (props) => {
  
        let placeHolderRut = ''
        if (item[0].format==='DOTS') {
          placeHolderRut = 'xxxxxxxx-x';
        } else if(item[0].format==='DASH') {
          placeHolderRut = 'xx.xxx.xxxx';
        } else {
          placeHolderRut = 'xxxxxxxxx';
        }

        return (
          <FormRow>
          <FormFieldName>{props.label}{props.required===true? '*':'' }</FormFieldName>
          <StyleInput type="string"
            value={props.value}
            required={props.required}
            placeholder={placeHolderRut}
            maxLength="12"
            onChange={(event) => props.onChange(
              (event.target.value && event.target.value!== '-')?format(event.target.value):''
              )} />
          </FormRow>
        );
      };

      let itemVal = `${(rutFieldArr[i])[0].label}` + "";
      let uiSchemaAux = { [itemVal]: { "ui:widget": inputRut } };
      Object.assign(uiSchemaArr, uiSchemaAux);
    });


    // DATE FILTERS
    let inputDate;

    dateFieldArr.forEach((item, i) => {
      let dateField = dateFieldArr[i];
      let itemVal = `${dateField[0].label}` + "";
      let maxDate = moment().format('YYYY-MM-DD');

      if(dateField[0].limitType === 'from') {
        inputDate = (props) =>
        <FormRow>
          <FormFieldName>{dateField[0].label}{dateField[0].mandatory===true? '*':'' }</FormFieldName>
        <StyleInput
          name={dateField[0].id}
          type='date'
          max={maxDate}
          {...props}
          onChange={(e) => {
              this.setUntilDate(document.getElementsByName(dateField[0].relatedFilterId)[0], e);
              props.onChange(e.target.value);
            }}
          onClick={(e) => { props.onChange(e.target.value)  }}

        />
        </FormRow>;
      } else {
        inputDate = (props) =>
        <FormRow>
        <FormFieldName>{dateField[0].label}{props.required===true? '*':'' }</FormFieldName>
        <StyleInput
          name={dateField[0].id}
          type="date"
          max={maxDate}
          {...props}
          onChange={(event) => {
                props.onChange(event.target.value) }}
          disabled
        />
        </FormRow>;
      }
      
      let uiSchemaAux = {[itemVal] : {"ui:widget": inputDate}};
      Object.assign(uiSchemaArr, uiSchemaAux);
    });

    // NUMBER FILTERS      
    let inputNumber = (props) => {
      return (
        <FormRow>
        <FormFieldName>{props.label}{props.required===true? '*':'' }</FormFieldName>
        <StyleInput type="number"
          value={props.value}
          required={props.required}
          // placeholder={props.label}
          maxLength="12"
          onChange={(event) => props.onChange(event.target.value)} />
        </FormRow>
      );
    };

    numberFieldArr.forEach((item, i) => {
      let itemVal = `${numberFieldArr[i]}` + "";
      let uiSchemaAux = { [itemVal]: { "ui:widget": inputNumber } };
      Object.assign(uiSchemaArr, uiSchemaAux);
    });

    // TEXT FILTERS
    let inputText = (props) => {
      return (
        <FormRow>
        <FormFieldName>{props.label}{props.required===true? '*':'' }</FormFieldName>
        <StyleInput type="string"
          value={props.value}
          required={props.required}
          // placeholder={props.label}
          maxLength="12"
          onChange={(event) => props.onChange(event.target.value)} />
        </FormRow>
      );
    };

    stringFieldArr.forEach((item, i) => {
      let itemVal = `${stringFieldArr[i]}` + "";
      let uiSchemaAux = { [itemVal]: { "ui:widget": inputText } };
      Object.assign(uiSchemaArr, uiSchemaAux);
    });

    // COMBOBOX FILTERS
    let inputSelect;
    let defaultValue;

    comboBoxFieldArr.forEach((item, i) => {

      defaultValue = item[i].defaultValue;

      inputSelect = (props) => {
      
        let selectedOption = Object.values(props.options.enumOptions).find(key => props.options.enumOptions[key] === defaultValue.label);
        const customStyles = {
          control: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            cursor: 'pointer',
            height: 50,
            maxWidth: '100%',
            borderColor: '#CCC',
            fontSize: '14px',
            color: '#6b6767',
            fontFamily: 'sans-serif'
          }),

          option: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            fontSize: '14px',
            color: '#6b6767',
          }),

          singleValue: (provided) => ({
            ...provided,
            fontSize: '14px',
            color: '#6b6767',
          }),
        }

        return (
          <FormRow>
          <FormFieldName>{props.label}{props.required===true? '*':'' }</FormFieldName>
          <Select
            name={props.label}
            onChange={(e) => props.onChange(e.value)}
            options={props.options.enumOptions}
            // value={this.props.selectOnChange?this.props.selectOnChange:selectedOption}
            defaultValue={selectedOption}
            // {...props}
            styles={customStyles}
            onClick={(e) => { props.onClick(e.value)  }}
          />
          </FormRow>
        );
      };

      let itemVal = `${(comboBoxFieldArr[i])[0].label}` + "";
      let uiSchemaAux = { [itemVal]: { "ui:widget": inputSelect } };
      Object.assign(uiSchemaArr, uiSchemaAux);
    });

    // Guardar UIScheme completo en state
    this.setState({ uiSchema: uiSchemaArr, isOpen: false });
  }

  /**
   * Calculamos maximo días en filtro fecha desde/hasta
   * 
   * @param {*} dateElement 
   */
  setUntilDate(dateElement, event) {

    let minvalue = moment(event.target.value).format('YYYY-MM-DD');
    let maxvalue = moment(event.target.value).add(this.state.maxDays, 'days').format('YYYY-MM-DD');
    let currentDate = moment().format('YYYY-MM-DD');

    // si el valor maximo es mayor a la fecha actual
    if(maxvalue > currentDate) {
      maxvalue = currentDate;
    }

      dateElement.disabled=false;
      dateElement.value='';
      dateElement.min=minvalue;
      dateElement.max=maxvalue;
      
  }

  /**
   * Obtenemos el formulario desde servicio
   */
  getForm() {
    try {

      const instanceId = localStorage.getItem("instanceId");

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY
        }
      };

      fetch(`${API_FORMS}api/rpa/core/v1/configuration/${instanceId}/processQuery`, requestOptions)
        .then(res => res.json())
        .then(res => {
          if (res.status === 200) {
            this.setState({ servicedata: res.data });
            this.parseSchema(res.data.section);
          } else {
            this.setState({ modalChildren: 'error', isClosable: true });
            console.log('Error consulta formulario: ');
          }
        });
    } catch (err) {
      this.setState({ modalChildren: 'error', isClosable: true });
      console.log('Error consulta formulario: ', err);
    }
  }

  /**
   * Submit - Buscar
   * 
   * @param {*} param0 
   * @param {*} e 
   */
  onSubmit = ({ formData }, e) => {
    // e.preventDefault();
    // e.stopPropagation();

    try {

      // Llamar servicio consulta
      this.state.formData = formData;
      this.search(this.state.currentStatePage, e);

    } catch (err) {
      this.setState({ modalChildren: 'error', isClosable: true });
      console.log('Error Submit : ', err);
    }
    
  }

  // Llamada a servicio consulta
  search = (page, e) => {

    // e.preventDefault();

    try {

      let serviceDataOriginal = { ...this.state.servicedata.section.filters };
      let fieldExist = false;
      let dateFormat = '';
      let formData = this.state.formData;

      // setear valor filtros en json original
      for (let item in serviceDataOriginal) {
        fieldExist = false;

        for (const property in formData) {
          if (serviceDataOriginal[item].label === property) {

            // Si es fecha formateamos. si no es fecha asignamos directamente
            if (serviceDataOriginal[item].elementType === 'datePicker') {

              dateFormat = moment(formData[property]).clone().format('DD-MM-YYYY');
              serviceDataOriginal[item].filtersValue = dateFormat;
              fieldExist = true;

            } else if (serviceDataOriginal[item].elementType === 'textBoxRut') {

              let formatedRut;
              
              if (serviceDataOriginal[item].format === 'DOTS') {
                formatedRut = formData[property].replaceAll('.', '');
              } else if (serviceDataOriginal[item].format === 'DASH') {
                formatedRut = formData[property].replaceAll('-', '');
              } else {
                formatedRut = clean(formData[property]);
              }

              // Si el campo se borra manualmente el valor es '-' o ''
              if(formatedRut && formatedRut !== '-' && formatedRut !== '') {
                serviceDataOriginal[item].filtersValue = formatedRut;
                fieldExist = true;
              } else {
                fieldExist = false;
              }

            } else {

              if(formData[property] && formData[property] !== "") {
                  serviceDataOriginal[item].filtersValue = formData[property];
                  fieldExist = true;
              } else {
                  // El campo existe pero tiene valor vacío ""
                  fieldExist = false;
              }

            }

          }
        }

        // Si no se completa el filtro no se envía
        if (!fieldExist) {
          delete serviceDataOriginal[item];
        }
      };

      // armar json consulta
      let searchData = {
        "instanceId": localStorage.getItem("instanceId"),
        "marker": page || 1,
        "filters": []
      };
      searchData.filters = Object.values(serviceDataOriginal);

      // preparar llamada a servicio
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY
        }
      };

      // abrir modal "loading"
      this.setState({
        isOpen: true,
        modalChildren: "loader",
        isClosable: false,
        totalPages: 0
      });

      // Llamar a servicio
      fetch(`${API_FORMS}api/rpa/core/v2/process/detail/find`, requestOptions)
        .then(res => res.json())
        .then(res => {

          if (res.status === 200) {

            let columnsTable = this.getColumns(res.data.details[0], e);

            this.setState({
              columnsTable: columnsTable,
              dataTable: res.data.details,
              pagesSize: res.data.details.length,
              currentMarker: res.data.marker,
              totalPages: res.data.pages,
              requestSearch: requestOptions
            });

            // cerrar modal loading
            this.setState({ isOpen: false, modalChildren: '', isClosable: true });

          } else {
            this.setState({ modalChildren: 'notFound', isClosable: true });
            console.log('Error servicio consulta');
          }

        });
    } catch (err) {
      this.setState({ modalChildren: 'notFound', isClosable: true });
      console.log('Error consulta: ', err);
    }
  }

  /**
   * Obtener columnas de la tabla
   * 
   * @returns
   */
  getColumns(fields, e) {
    // e.preventDefault();

    let columns = [];

    for (const property in fields) {
      let linkJson = {};
      let linkStr = "";

      // Si el campo es tipo Link
      if (fields[property] && fields[property].includes('isLink')) {
        // formatear
        linkStr = JSON.parse(JSON.stringify(fields[property]));
        // obtener json
        linkJson = JSON.parse(linkStr);
      }


      if (linkJson.isLink !== undefined && linkJson.isLink === 'href') {
        columns.push({
          Header: property,
          accessor: linkStr,
          sortable: false,
          filterable: false,
          minWidth: 'auto',
          width: 'auto',
          style: { whiteSpace: "unset", paddingTop: 30 },

          Cell: ({ original }) => <a href={JSON.parse(original[property]).pathName || '#'} target='_blank'>{JSON.parse(original[property]).toShow}</a>,
        });

      } else if (linkJson.isLink !== undefined && linkJson.isLink === 'modal') {

        columns.push({
          Header: property,
          accessor: property,
          sortable: false,
          filterable: false,
          minWidth: 'auto',
          width: 'auto',
          style: { whiteSpace: "unset", paddingTop: 30 },

          Cell: ({ row }) => (

            <button style={{ float: "right", marginTop: "1%" }}
              onClick={() => {

                this.setState({
                  isOpen: true,
                  modalChildren: 'comment',
                  modalMessage: JSON.parse(row[property]).pathName + '',
                  isClosable: true,
                });

              }}>{JSON.parse(row[property]).toShow}</button>

          )
        });

      } else {
        columns.push({
          Header: property,
          accessor: property,
          minWidth: 'auto',
          width: 'auto',
          style: { whiteSpace: "unset", paddingTop: 30 }
        });
      }
        
    };

    return columns;
  }


  /**
   * 
   * Validaciones custom 
   * 
   * @param {*} formData 
   * @param {*} errors 
   * @returns 
   */
  validate(formData, errors) {
    // console.log('validate formData: ', formData);
    // console.log('validate errors: ', errors);

    return errors;
  }

  objectFieldTemplate(props) {
    return (
      <Fragment>
        <FormTitle>
          <b style={{ fontSize: '20px' }}>{props.title}</b>
          <hr style={{ width: '100%' }} />

          <div style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
            {
              props.properties.map(element =>
              (<span style={{ display: 'inline-block', margin: '0px 5px' }}>
                {element.content}
              </span>)
            )
            }
          </div>

        </FormTitle>
      </Fragment>
    )
  }

  /**
   * Obtener registros para exportar
   * 
   * @returns 
   */
  render() {

    return (
      <Fragment>
        <FormBlock>
          <Form 
            id="dynamicform"
            // ref={this.formRef}
            // clearFormKey={this.state.clearFormKey}
            // validate={this.validate}
            schema={this.state.schema}
            uiSchema={this.state.uiSchema}
            formData={this.state.formData}
            onSubmit={this.onSubmit}
            ObjectFieldTemplate={this.objectFieldTemplate}
          >

            <ActionForm>
              <Buttons.Primary color={"primary"} variant="contained" style={{ marginRight: '5px' }} type="submit">Buscar</Buttons.Primary>
              <Buttons.Clear color={"secondary"} variant="contained" onClick={this.clearForm}>Limpiar</Buttons.Clear>


            </ActionForm>
          </Form>

          {this.state.columnsTable && this.state.dataTable ?
            <div>
              <br></br>

              <DownloadData getFullData={this.state.requestSearch} />
              <ReactTable
                data={this.state.dataTable}
                columns={this.state.columnsTable}
                className="-striped -highlight"
                style={{ textAlign: "center", fontSize: "12px" }}
                className="-striped -highlight"

                pages={this.state.totalPages}
                previousText={"anterior"}
                nextText={"siguiente"}
                PaginationComponent={Pagination}
                defaultPageSize={this.state.pagesSize}
                pageSize={this.state.pagesSize}

                manual
                onFetchData={(tableState, instance) => {
                  if (this.state.currentStatePage > 0) {
                    this.search(tableState.page + 1);
                  }
                  this.setState({ currentStatePage: 1 });
                }}
              />
            </div>
            : ''}
        </FormBlock>

        <Modal show={this.state.isOpen}
          onClose={() => {
            this.state.isClosable
              ? this.setState({
                isOpen: !this.state.isOpen
              })
              : console.warn("worker on something...");
          }}>
          <Template name={this.state.modalChildren} message={this.state.modalMessage} />
        </Modal>

      </Fragment>
    )
  }
}

export default ConsultaProcesosComponent;