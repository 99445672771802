
import {
  bgGreenHeader,
  bgRedHeader,
  bgBlueheader,
  bgGreyheader
} from "./styles";

/**
 * Obtener fecha actual
 */
export const getCurrentDate = (separator) => {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${date}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${year}`;
};

/**
 *
 * Para calcular la fecha fin del datapicker
 */
export const getFinishMaxDate = (e) => {
  //Si cambia la fecha de inicio. fin es inicio + 3 meses
  if (e === null || e === undefined) {
    return null;
  }
  let monthMax = 3;

  let day = e.getDate() < 10 ? "0" + e.getDate() : e.getDate();
  let month = e.getMonth() + 1 < 10 ? "0" + (e.getMonth() + 1) : e.getMonth() + 1;
  let year = e.getFullYear();
  let endDate = new Date(year + "/" + month + "/" + day);

  endDate = new Date(
    endDate.setMonth(endDate.getMonth() + monthMax)
  );

  return endDate;
};

/**
 *
 * Para calcular la fecha inicio del datapicker
 */
export const getInitMinDate = (e) => {
  //Si cambia la fecha de fin. inicio es fin - 3 meses
  if (e === null || e === undefined) {
    return null;
  }
  let monthMin = -3;

  let day = e.getDate() < 10 ? "0" + e.getDate() : e.getDate();
  let month = e.getMonth() + 1 < 10 ? "0" + (e.getMonth() + 1) : e.getMonth() + 1;
  let year = e.getFullYear();
  let endDate = new Date(year + "/" + month + "/" + day);

  endDate = new Date(
    endDate.setMonth(endDate.getMonth() + monthMin)
  );

  return endDate;
};

/**
 * Sumar meses a una fecha
 */
export const addMonths = (dt, n) => {
  return new Date(dt.setMonth(dt.getMonth() + n));
};

/** formato montos dashboard */
export const number_format = (amount, decimals) => {
  amount += ""; // por si pasan un numero en vez de un string
  amount = parseFloat(amount.replace(/[^0-9\.]/g, "")); // elimino cualquier cosa que no sea numero o punto

  decimals = decimals || 0; // por si la variable no fue fue pasada

  // si no es un numero o es igual a cero retorno el mismo cero
  if (isNaN(amount) || amount === 0) return parseFloat(0).toFixed(decimals);

  // si es mayor o menor que cero retorno el valor formateado como numero
  amount = "" + amount.toFixed(decimals);

  var amount_parts = amount.split("."),
    regexp = /(\d+)(\d{3})/;

  while (regexp.test(amount_parts[0]))
    amount_parts[0] = amount_parts[0].replace(regexp, "$1,$2");

  return amount_parts.join(".");
};

/** Valida que el valor sea numerico */
export const validateNumber = (value) => {
  const regexp = /^[0-9]*$/;

  return regexp.test(value);
}

/** Para saber el color que se debe pintar las cajas del dashboard */
export const get_box_color = (titulo) => {
  let colorBox = "";
  if (titulo === "PROCESADOS") {
    colorBox = bgGreenHeader;
  } else if (titulo === "ANULADOS") {
    colorBox = bgRedHeader;
  } else if (titulo === "TOTAL") {
    colorBox = bgGreyheader;
  } else {
    colorBox = bgBlueheader;
  }

  return colorBox;
};

/** Obtiene las opciones para un select.
 * El campo label y value varían segun el servicio invocado  **/
export const getFilterOptions = (endpoint, labelField, valueField, token) => {
  let optionsResponse = [];

  const requestOptions = (token !== null && token !== undefined) ?
  {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token
    }
  } : {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  };

  fetch(endpoint, requestOptions)
  .then((result) => result.json())
  .then((response) => {
    let i = 0;
    for (i = 0; i < response.length; i++) {
      optionsResponse.push({
        label: response[i][labelField],
        value: response[i][valueField]
      });
    }
  });

  return optionsResponse;
};

/** obtener options para select estados */
export const getStatuOptions = () => {
  let optionsResponse = [];

  optionsResponse.push({
    label: "PREPARED",
    value: 1
  });
  optionsResponse.push({
    label: "PROCESSED",
    value: 2
  });
  optionsResponse.push({
    label: "PENDING",
    value: 3
  });
  optionsResponse.push({
    label: "PARTIALLY_PROCESSED",
    value: 4
  });

  return optionsResponse;
};

export const getStateOptions = (endpoint, labelField, valueField) => {
  let optionsResponse = [];
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  };
  fetch(endpoint, requestOptions)
  .then(function (result) {
    if (result.ok) {
      result.json().then((response) => {
        let i = 0;
        for (i = 0; i < response.statusTypes.length; i++) {
          optionsResponse.push({
            label: response.statusTypes[i][labelField],
            value: response.statusTypes[i][valueField]
          });
        }
      });
    }
  })
  .catch((error) => {
    console.log("Hubo un problema con la petición Fetch:" + error.message);
  });

  return optionsResponse;
};

// Obtener filtro contexto
export const getEngineOptions = (endpoint, labelField, valueField) => {
  let optionsResponse = [];

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  };

  fetch(endpoint, requestOptions)
  .then((result) => result.json())
  .then((response) => {
    let i = 0;
    for (i = 0; i < response.contexts.length; i++) {
      optionsResponse.push({
        label: response.contexts[i][labelField],
        value: response.contexts[i][valueField]
      });
    }
  })
  .catch((error) => {
    console.log("Hubo un problema con la petición Fetch:" + error.message);
  });

  return optionsResponse;
};

export const getTaskOptions = (endpoint) => {
  console.log(endpoint);

  let optionsResponse = [];

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  };

  fetch(endpoint, requestOptions)
  .then((result) => result.json())
  .then((response) => {
    if (response === null || response === undefined) {
      console.log("response is null o undefined");
      return;
    }
    let i = 0;
    for (i = 0; i < response.tasks.length; i++) {
      optionsResponse.push({
        label: response.tasks[i],
        value: response.tasks[i]
      });
    }
  });

  return optionsResponse;
};

/** Obtiene los eventos para un select.
 * El apikey varia segun variable entorno **/
export const getFilterEvents = (endpoint, labelField, valueField, apikey) => {
  let optionsResponse = [];

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "apikey": apikey
    }
  };

  fetch(endpoint, requestOptions)
  .then((result) => result.json())
  .then((response) => {
    let i = 0;
    for (i = 0; i < response.events.length; i++) {
      optionsResponse.push({
        label: response.events[i][labelField],
        value: response.events[i][valueField]
      });
    }
  });

  return optionsResponse;
};

/** Obtiene las opciones para un select.
 * La lista de label y el campo value varían segun el servicio invocado
 * Concatena los labels ingresados 
 * Remueve las opciones con el mismo id de los filters **/
export const getFilterOptionsMultiLabels = (endpoint, labelFields, valueField, token, filters) => {
  let optionsResponse = [];

  const requestOptions = (token !== null && token !== undefined) ?
  {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token
    }
  } : {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  };

  fetch(endpoint, requestOptions)
  .then((result) => result.json())
  .then((response) => {
    for (let i = 0; i < response.length; i++) {
      let exist = false;
      for(let j=0; j < filters.length; j++) {
        if(parseInt(response[i][valueField]) === parseInt(filters[j].id)) {
          exist = true;
          break;
        }
      }
      if (!exist) {
        let label = "";
        for (let j = 0; j < labelFields.length; j++) {
          let name = labelFields[j];
          label = label + ((label === "") ? "" : " - ") + response[i][name];
        }
        optionsResponse.push({
          label: label,
          value: response[i][valueField]
        });
      }
    }
  });
  
  return optionsResponse;
};
