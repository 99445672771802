import React, { Fragment, useEffect, useState } from "react";
import Buttons from "../UIComponents/button";
import {
  LoginContainer,
  LoginMain,
  LoginInfo,
  LoginTitleInfo,
  LoginTextInfo,
  LoginForm,
  LoginFormTitle,
  LoginFormText,
  LogoAligare,
  LoginInfoContent,
  ButtonBlock,
  StyleInput,
} from "./styles";
import * as env from "../../environment";
import InputError from "../UIComponents/inputError/index"
import Modal from "../modal";
import Template from "../modal/tempalte";
import { saveToken, saveUsername, clearSession, getApiKey } from "../utils/session";
import { currentProfile } from "../utils/currenProfile";
import SelectComponent from "../UIComponents/selectInput";

const { API_KEY, URL_BASE } = env[process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV];

//history is a prop of login 
const SelectInstanceComponent = ({ history }) => {
  //state for handle modals
  const [modal, updateModal] = useState({
    isOpen: false,
    modalChildren: "",
    isClosable: false
  })

  const [instances, setInstances] = useState([]);
  const [instance, setInstance] = useState('');
  const [apiKey] = useState(API_KEY);
  const [hidden, setHidden] = useState(false);
  //states abbreviation
  const { isOpen, modalChildren, isClosable } = modal;

  //when is clicked submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!instance) {
      setHidden(true);
    } else {
      history.push('/home');
    }
  };

  //enable or disable the button
  const isFormValid = () => {
    return true;
  };

  useEffect(() => {
    getInstances(localStorage.getItem('companyID'));
  }, []);

  const getInstances = (companyId) => {
    let endpoint = `${URL_BASE}instance/list/${companyId}`;
    console.log('endpoint getInstances: ', endpoint);

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": apiKey
      }
    };

    updateModal({ isOpen: true, modalChildren: 'loader', isClosable: false });

    fetch(endpoint, requestOptions)
      .then(res => res.json())
      .then(data => {
        if (data.status === 200) {
          let arrayInstances = [];
          data.data.forEach(val =>
            arrayInstances.push({
              label: val.instanceName,
              value: val.instanceId
            }));
          setInstances(arrayInstances);

            if(arrayInstances.length === 1) {
              localStorage.setItem('instanceId', data.data[0].instanceId);
              history.push('/home');
            } else {
              updateModal({ isOpen: false });
            }

        } else {
          updateModal({ isClosable: true, modalChildren: 'notFound' });
        }
      })
      .catch(err => {
        console.log('error getCompanies: ', err);
        updateModal({ isClosable: true, modalChildren: 'error' });
      })
  }

  const handleInstanceChange = (texto) => {
    localStorage.setItem('instanceId', texto.value);
    setInstance(texto.value)
  }

  const logOut = (e) => {
    e.preventDefault();
    history.push('/selectCompany')
  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <LoginContainer>
          <LoginMain>
            <LoginInfo>
              <LoginInfoContent>
                <LoginTitleInfo>Bienvenido</LoginTitleInfo>
                <LoginTextInfo>Sistema de RPA</LoginTextInfo>
              </LoginInfoContent>
            </LoginInfo>
            <LoginForm>
              <LogoAligare src="/images/logo-aligare.png" alt="Logo Aligare" />
              <LoginFormTitle>Seleccione su Instancia</LoginFormTitle>

              <LoginFormText>Instancia</LoginFormText>
              <SelectComponent options={instances} onChange={handleInstanceChange} />
              <div style={{ marginTop: '5px' }}>
                <InputError isShow={hidden} value={'Selecciona una Instancia'} />
              </div>
              <ButtonBlock>
              <Buttons.Secondary onClick={logOut} style={{ marginRight: '5px', maxWidth: '110px' }}>Volver</Buttons.Secondary>
                <Buttons.Primary type="submit" disabled={!isFormValid()}
                  style={!isFormValid() ? { backgroundColor: '#F2AAB7' } : { backgroundColor: '#ff4063' }}>
                  Ingresar
              </Buttons.Primary>
              </ButtonBlock>
            </LoginForm>
          </LoginMain>
        </LoginContainer>
      </form>
      <Modal
        show={isOpen}
        onClose={() => {
          isClosable
            ? updateModal({
              isOpen: !isOpen,
            })
            : console.warn("worker on something...");
        }}
      >
        <Template name={modalChildren} />
      </Modal>
    </Fragment>
  );

};
export default SelectInstanceComponent;
