import React, { Fragment, useEffect, useState } from "react";
import ReactExport from "react-export-excel";
import styled from 'styled-components';
import { VscFilePdf } from "react-icons/vsc";
import { AiOutlineFileExcel } from "react-icons/ai";
import ReactDOMServer from 'react-dom/server'
import * as env from "../../environment";
import { jsPDF } from "jspdf";
import 'jspdf-autotable';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { API_KEY, API_FORMS } = env[process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV];

var pdf = require('html-pdf');
let win = null;

const DownloadData = (props) => {

  let name = props.name

  const [datos, setDat] = useState({ data: [], columns: [] })

  useEffect(() => {
    name = name ? name : 'datos';
  })

  useEffect(async () => {
    if (props.getFullData ) {  
      let newDat = await getFullData(props.getFullData.body);
      setDat(newDat)
    }
  }, [props] )

  const getFullData = async(requestData) => {

    // recuperamos request de search original y quitamos "marker"
    let requestSearch = JSON.parse(requestData);
    delete requestSearch.marker;
    
    // console.log('requestSearch: ' + JSON.stringify(requestSearch));

    // preparar llamada a servicio
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(requestSearch),
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY
      }
    };

    // Llamar a servicio
    let response;
    try {
      const resp = await fetch(`${API_FORMS}api/rpa/core/v2/process/detail/find`, requestOptions)
      response = await resp.json()
    } catch (err) {
      console.log('error getFullData: ', err)
      this.setState({ modalChilren: 'error', isClosable: true })
    }

    // Obtener columnas
    const columns = Object.keys(response.data.details[0]);

    // Obtener registros
    const data = response.data.details;

    return { data, columns }
  }

  return (
    <Fragment>
      <table style={{ borderWidth: '0px' }}>
        <th>
          <ExcelFile 
            filename = {name}
            element={
              <AiOutlineFileExcel style={{ width: 50 + 'px', height: 30 + 'px' }} onClick={(e) => e.preventDefault()} className="btnAiOutlineFileExcel" />
            }
          >
            {datos.columns ?
              <ExcelSheet data={datos.data} name="Hoja1">
                {datos.columns.map(column => (
                  <ExcelColumn label={`${column}`} value={`${column}`} />
                ))}
              </ExcelSheet>
              :
              <ExcelSheet data={[{ NNN: 'NNN' }]}>
                <ExcelColumn label="NNN" value="Error en la busqueda" />
              </ExcelSheet>
            }
          </ExcelFile>
        </th>
        <th>
          <VscFilePdf onClick={(e) => {
            e.preventDefault();

            datos.data.forEach((dat, index) => {
              Object.keys(dat).map((key, idx) => {
                if(datos.data[index][key]) {
                  datos.data[index][key] = datos.data[index][key].toString();
                } else {
                  datos.data[index][key] = '';
                }
              });
            });

            let head = [{}];
            datos.columns.map(column => {
              console.log(column);
               head[0][`${column}`] = column;
            })

            console.log(head);

            console.log(datos.data);

            const fileName = `${name}.pdf`;

            console.log(Object.keys(datos.data[0]));

            console.log(`escribiendo archivo: ${ fileName }`);


            var doc = new jsPDF('l');

            var body = datos.data;

            if(name === 'sendCorrection') {
              doc.autoTable({
                head: head,
                body: body,
                margin: 1,            
                styles: { cellWidth: 25},
                rowPageBreak: 'auto',
                bodyStyles: { valign: 'top' }
              })
            } else {
              doc.autoTable({
                head: head,
                body: body,
                margin: 1,            
                styles: { cellWidth: 'auto'},
                rowPageBreak: 'auto',
                bodyStyles: { valign: 'top' }
              })
            }          

            doc.save(`${name}.pdf`);                  
          }}
            style={{ width: 50 + 'px', height: 30 + 'px' }} />
        </th>
      </table>
    </Fragment>
  );
};

export default DownloadData;


export const Download = styled.button`
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: bold;
  font-size: 14px;
  height: 32px;
  padding: 0 30px;
  outline: none;
  width: 100%;
  max-width: 200px;
  background: #01723a;
  opacity: 0.4;
  color: #ffffff;
  margin: 20px;
  margin-top: -8px;

  &:hover {
    opacity: 1;
  }
`;
