import styled from 'styled-components';

export const HeaderModule = styled.div`
  background: #FFFFFF;
  border-radius: 4px;
  border-top: 15px solid #ff4063;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 60px;
  margin: 0 0 30px 0;
  padding: 5px 10px;
  width: 100%;
`;

export const AlertBlock = styled.div`
 display: flex;
 flex-direction: row;
 padding: 5px  20px;
 width: auto;
`;

export const AlertImg = styled.img`
  height: 25px;
  width: 25px;
`;

export const AlertNumber = styled.div`
 align-items: center;
 background: #ff4063;
 border-radius: 50%;
 color: #FFFFFF;
 display: flex;
 font-weight: bold;
 font-size: 13px;
 height: 25px;
 justify-content: center;
 width: 25px;
`;

export const InputSearchHeader = styled.input`
  background: url("./images/search.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px 20px;
  border: none;
  font-size: 13px;
  outline: none;
  margin: 0 0 0 10px;
  padding: 0 0 0 27px;
  width: 200px;
`;

export const ContentAligare = styled.div`
 align-items: center;
 display: flex;
 flex-direction: row;
 height: 25px;
 margin: 0 0 0 auto;
 padding: 4px 20px 0 0 ;
 width: auto;

 @media (max-width: 615px) {
  display: none;
  }
`;

export const LogoAligare = styled.img`
  height: 22px;
  width: 60px;
`;

export const ProductName = styled.p`
  border-left: 1px solid #CCCCCC;
  font-size: 12px;
  margin: 0 0 0 10px;
  padding: 5px 0 0 10px;
`;