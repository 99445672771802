import styled from 'styled-components';

export const Container  = styled.div`
 box-sizing: border-box;
 display: flex;
 flex-direction: row;
 font-weight: normal;
 margin: 0 auto;
 max-width: 1280px;
 padding: 25px 15px;
 width: 100%;

 @media (max-width: 720px) {
  display: block
}
`;

export const MainHome = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const PodContainer = styled.div`
 box-sizing: border-box;
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
 padding: 20px 0;
 width: 100%;
`;

export const TitleMain = styled.h2`
  border-bottom: 1px solid #CCCCCC;
  display: flex;
  font-size: 35px;
  margin: 0 0 20px 0;
  padding: 0 0 5px 0;
  width: 100%;
`;

export const SubTitle = styled.div`
  align-items: center;
  background: #FFFFFF;
  border-left: 8px solid #4a4a4a;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  font-size: 18px;
  height: 50px;  
  padding: 0 10px;
  width: 100%;
`;

export const BlockContent = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
`;

