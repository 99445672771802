import React, { Component, Fragment } from 'react';
import routes from './Routes';
import { BrowserRouter as Router, Route, Switch, useLocation, Redirect } from 'react-router-dom';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentProfileFunctionalities: []
    }
  }


  render() {
    const RouteWithSubRoutes = (route) => (
      <Route path={route.path} exact={route.exact} key={`route${route.path}`} render={(props) => (
        <route.component {...props} { ...route.props } routes={route.routes} render={() => (console.log('AQUII'))} />
      )} />
    )

    return (
      <Router>
        <Switch>
          {
            routes.map((route) => (
              <RouteWithSubRoutes key={route.path} {...route} exact />
            ))
          }
        </Switch>
      </Router>

    );
  }
} 

export default App;