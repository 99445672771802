import React, { Fragment, useEffect, useState } from "react";
import Buttons from "../UIComponents/button";
import {
  LoginContainer,
  LoginMain,
  LoginInfo,
  LoginTitleInfo,
  LoginTextInfo,
  LoginForm,
  LoginFormTitle,
  LoginFormText,
  LogoAligare,
  LogoSalfa,
  LoginInfoContent,
  ButtonBlock,
  StyleInput,
} from "./styles";
import * as env from "../../environment";
import ErrorInput from "../UIComponents/inputError";
import Modal from "../modal";
import Template from "../modal/tempalte";
import { saveToken, saveUsername, saveEmail } from "../utils/session";
import { useParams } from 'react-router-dom'

const { API_KEY, URL_BASE } = env[process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV];

import Amplify, { Auth } from 'aws-amplify';

//history is a prop of login 
const LoginComponent = ({ history }) => {
  //state for user inputs
  //user variable contains the states
  //updateUser is method for update states of user, it's like setstate
  const [user, updateUser] = useState({
    username: "",
    pass: ""
  });
  //state for handler erros
  const [handleErrors, updateHandleErros] = useState({
    sUsername: false,
    sPass: false
  });
  //state for handle modals
  const [modal, updateModal] = useState({
    isOpen: false,
    modalChildren: "",
    isClosable: false,
    message: ""
  })

  const [apiKey] = useState(API_KEY);

  const [getHoldingResponse, setGetHoldingResponse] = useState({
    holdingId: undefined,
    holdingName: undefined,
    holdingPoolId: undefined,
    holdingPoolClientId: undefined,
    holdingPoolRegion: undefined,
  });

  //states abbreviation
  const { username, pass } = user;
  const { sUsername, sPass } = handleErrors;
  const { isOpen, modalChildren, isClosable, message } = modal;

  const { holdingId } = useParams()

  //when is clicled submit
  const handleSubmit = (e) => {
    e.preventDefault();
    authentication();
  };
  //get token from service
  const authentication = () => {

    let awsconfig = {
      Auth: {
        region: getHoldingResponse.holdingPoolRegion,
        userPoolId: getHoldingResponse.holdingPoolId,
        userPoolWebClientId: getHoldingResponse.holdingPoolClientId
      }
    };
    Amplify.configure(awsconfig);

    updateModal({ ...modal, isOpen: true, modalChildren: "load-Login", isClosable: false });
    Auth.signIn(username, pass)
      .then(user => {

        if (!user.signInUserSession) {
          updateModal({ ...modal, isOpen: true, modalChildren: "notFound", isClosable: true });
          throw new Error("errorPass");
        } else {
          //console.log('------------> user : ', user);
          //console.log('------------> token : ', user.signInUserSession.accessToken.jwtToken);
          //console.log('------------> companyIds : ', user.attributes['custom:companyIds']);
          //console.log('------------> email 1 : ', user.attributes['email']);

          saveToken(user.signInUserSession.accessToken.jwtToken);
          saveUsername(user.username);
          saveEmail(user.attributes['email']);

          updateModal({ ...modal, isOpen: false, modalChildren: "", isClosable: true });

          let dataToken = user.attributes['custom:companyIds'];
          localStorage.setItem('idCompanys', dataToken);
          history.push("/selectCompany");
        }

      })
      .catch(err => {
        if (!err.message) {
          console.log('Error when signing in: ', err)
          updateModal({ ...modal, isOpen: true, modalChildren: "errorPass", isClosable: true });
        } else {
          console.log('Error when signing in: ', err.message)
          updateModal({ ...modal, isOpen: true, modalChildren: "error", isClosable: true, message: err.message });
        }
      })

  };

  Auth.get
  //when typed input
  const handleChange = (e) => {
    updateUser({
      ...user,
      [e.target.name]: e.target.value
    });
  };

  //enable or disable the button
  const isFormValid = () => {
    if (
      username !== undefined &&
      username !== null &&
      username !== "" &&
      pass !== undefined &&
      pass !== null &&
      pass !== ""
    ) {
      return true;
    }
    return false
  };

  //when input focus is lost
  const handleBlur = (e) => {
    let name = `s${e.target.name[0].toUpperCase() + e.target.name.slice(1)}`;
    if (
      e.target.value === undefined ||
      e.target.value === "" ||
      e.target.value === null
    ) {
      updateHandleErros({
        ...handleErrors,
        [name]: true
      });
    } else {
      updateHandleErros({
        ...handleErrors,
        [name]: false
      });
    }
  };

  const getHolding = (idHolding) => {
    if (!idHolding || idHolding === 'noHolding') {
      return updateModal({ ...modal, modalChildren: 'error', isOpen: true, isClosable: true, message: 'Holding inválido' })
    }
    
    const endpoint = `${URL_BASE}holding/${idHolding}`;
    console.log('endpoint getHolding: ', endpoint);

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": apiKey
      }
    };

    updateModal({ ...modal, modalChildren: 'loader', isOpen: true, isClosable: false });

    fetch(endpoint, requestOptions)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          const data = result.data[0];
          setGetHoldingResponse({
            holdingId: data.holdingId,
            holdingName: data.holdingName,
            holdingPoolClientId: data.holdingPoolClientId,
            holdingPoolId: data.holdingPoolId,
            holdingPoolRegion: data.holdingPoolRegion
          });
          localStorage.setItem('holdingId', data.holdingId)
          localStorage.setItem('holdingName', holdingId)
          updateModal({ isOpen: false });
        } else {
          console.log('No se encontraron datos para el holdingId: ', holdingId);
          updateModal({ ...modal, modalChildren: 'notFound', isOpen: true, isClosable: true });
        }
      })
      .catch(err => {
        console.log('error getHolding: ', err);
        updateModal({ ...modal, modalChildren: 'error', isOpen: true, isClosable: true });
      });
  }

  useEffect(() => {
    getHolding(holdingId)
  }, [])

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <LoginContainer>
          <LoginMain>
            <LoginInfo>
              <LoginInfoContent>
                <LoginTitleInfo>Bienvenido</LoginTitleInfo>
                <LoginTextInfo>Sistema de RPA</LoginTextInfo>
              </LoginInfoContent>
            </LoginInfo>
            <LoginForm>
                <div style={{ alignItems: 'left' }}>
                <LogoAligare src="/images/logo-aligare.png" alt="Logo Aligare"/>
                  <LogoSalfa src="/images/logo_salfaa.jpg" alt="Logo Aligare"/>
                  </div>
              <LoginFormTitle>Mi cuenta</LoginFormTitle>

              <LoginFormText>Ingrese su usuario</LoginFormText>
              <StyleInput
                type="text"
                value={username}
                onChange={handleChange}
                onBlur={handleBlur}
                name="username"
              />
              <div>
                <ErrorInput isShow={sUsername} value="Este campo es obligatorio" />
              </div>

              <LoginFormText>Ingrese su contraseña</LoginFormText>
              <StyleInput
                type="password"
                value={pass}
                onChange={handleChange}
                onBlur={handleBlur}
                name="pass"
              />
              <div>
                <ErrorInput isShow={sPass} value="Este campo es obligatorio" />
              </div>

              <ButtonBlock>
                <Buttons.Primary type="submit" disabled={!isFormValid()}
                  style={!isFormValid() ? { backgroundColor: '#F2AAB7' } : { backgroundColor: '#ff4063' }}>
                  Ingresar
              </Buttons.Primary>
              </ButtonBlock>
            </LoginForm>
          </LoginMain>
        </LoginContainer>
      </form>
      <Modal
        show={isOpen}
        onClose={() => {
          isClosable
            ? updateModal({
              isOpen: !isOpen,
            })
            : console.warn("worker on something...");
        }}
      >
        <Template name={modalChildren} message_error={message} />
      </Modal>
    </Fragment>
  );
};
export default LoginComponent;
