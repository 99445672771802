import React, { Fragment, useEffect, useState } from "react";
import Buttons from "../UIComponents/button";
import {
  LoginContainer,
  LoginMain,
  LoginInfo,
  LoginTitleInfo,
  LoginTextInfo,
  LoginForm,
  LoginFormTitle,
  LoginFormText,
  LogoAligare,
  LoginInfoContent,
  ButtonBlock,
  StyleInput,
} from "./styles";
import * as env from "../../environment";
import InputError from "../UIComponents/inputError/index"
import Modal from "../modal";
import Template from "../modal/tempalte";
import { saveToken, saveUsername, clearSession, getApiKey } from "../utils/session";
import { currentProfile } from "../utils/currenProfile";
import SelectComponent from "../UIComponents/selectInput";

const { API_KEY, URL_BASE } = env[process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV];

//history is a prop of login 
const SelectAgencyComponent = ({ history }) => {
  //state for handle modals
  const [modal, updateModal] = useState({
    isOpen: false,
    modalChildren: "",
    isClosable: false
  })

  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState('');
  const [apiKey] = useState(API_KEY);
  const [hidden, setHidden] = useState(false);
  //states abbreviation
  const { isOpen, modalChildren, isClosable } = modal;

  //when is clicked submit
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(company)
    if (!company) {
      setHidden(true);
    } else {
      history.push('/selectInstance');
    }
  };

  //enable or disable the button
  const isFormValid = () => {
    return true;
  };

  useEffect(() => {
    getCompanies(localStorage.getItem('idCompanys'));
  }, []);

  const getCompanies = (idCompanies) => {
    let endpoint = `${URL_BASE}company/${idCompanies}`;
    console.log('endpoint getCompanies: ', endpoint);

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": apiKey
      }
    };

    updateModal({ isOpen: true, modalChildren: 'loader', isClosable: false });

    fetch(endpoint, requestOptions)
      .then(res => res.json())
      .then(data => {
        if (data.status === 200) {
          let arrayCompanies = [];
          data.data.forEach(val =>
            arrayCompanies.push({
              label: val.companyName,
              value: val.companyId
            }));            
          setCompanies(arrayCompanies);

          if(arrayCompanies.length === 1) {

            localStorage.setItem('companyID', data.data[0].companyId);
            setCompany(data.data[0].companyId)

            history.push('/selectInstance');
          } else {
            updateModal({ isOpen: false });
          }

        } else {
          updateModal({ isClosable: true, modalChildren: 'notFound' });
        }
      })
      .catch(err => {
        console.log('error getCompanies: ', err);
        updateModal({ isClosable: true, modalChildren: 'error' });
      })
  }

  const handleCompanyChange = (texto) => {
    localStorage.setItem('companyID', texto.value);
    setCompany(texto.value)
  }

  const logOut = (e) => {
    e.preventDefault();
    localStorage.clear();
    window.location = '/';
  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <LoginContainer>
          <LoginMain>
            <LoginInfo>
              <LoginInfoContent>
                <LoginTitleInfo>Bienvenido</LoginTitleInfo>
                <LoginTextInfo>Sistema de RPA</LoginTextInfo>
              </LoginInfoContent>
            </LoginInfo>
            <LoginForm>
              <LogoAligare src="/images/logo-aligare.png" alt="Logo Aligare" />
              <LoginFormTitle>Seleccione su Compañia</LoginFormTitle>

              <LoginFormText>Compañia</LoginFormText>
              <SelectComponent options={companies} onChange={handleCompanyChange} />
              <div style={{ marginTop: '5px' }}>
                <InputError isShow={hidden} value={'Selecciona una compañia'} />
              </div>
              <ButtonBlock>
                <Buttons.Secondary onClick={logOut} style={{ marginRight: '5px', maxWidth: '110px' }}>Salir</Buttons.Secondary>
                <Buttons.Primary type="submit" disabled={!isFormValid()}
                  style={!isFormValid() ? { backgroundColor: '#F2AAB7' } : { backgroundColor: '#ff4063' }}>
                  Ingresar
                </Buttons.Primary>
              </ButtonBlock>
            </LoginForm>
          </LoginMain>
        </LoginContainer>
      </form>
      <Modal
        show={isOpen}
        onClose={() => {
          isClosable
            ? updateModal({
              isOpen: !isOpen,
            })
            : console.warn("worker on something...");
        }}
      >
        <Template name={modalChildren} />
      </Modal>
    </Fragment>
  );

};
export default SelectAgencyComponent;
