import styled from 'styled-components';

export const background = {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.3)',
    padding: 50,
    zIndex: 100
  }
export const backgroundModal = {
    backgroundColor: '#fff',
    borderRadius: 5,
    maxWidth: 500,
    minHeight: 400,
    margin: '0 auto',
    padding: 30
}

export const backgroundModalForDetail = {
    backgroundColor: '#fff',
    borderRadius: 5,
    maxWidth: 650,
    minHeight: 600,
    margin: '0 auto',
    padding: 30
}
export const btnOnClose ={
    width: '60%',
    height: '30px',
    borderRadius: '5px',
    border: 0,
    background: '#ff4063',
    color: '#FFFFFF',
    
}
export const modalContent ={
    position: 'relative',
    width: '100%',
    height: '300px',
    textAlign: 'center',
}

export const TitleRequestResponse = styled.h1`
display: inline;
margin-right: 84%;
font-size: 17px;
text-transform: uppercase;
opacity: 0.7;
`

export const ContainerRequestResponse = styled.div`
width: 100%;
height: 200px;
background-color: aliceblue;
opacity: 0.5;
border-radius: 8px;
border: 1px solid wheat;
padding-top: 10px;
padding-bottom: 10px;
margin-bottom: 40px;
overflow: overlay;
text-align: left;
color: black;
opacity: 1;
font-size: 15px;
font-weight: 580;
`